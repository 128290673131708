<template>
  <page-layout ref="layout">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`All-State - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Selections" :to="{ name: 'all-state-selections' }" active/>
    </template>

    <template #dropdown-options="">
      <b-dropdown-item @click="refresh">
        <font-awesome-icon icon="fa-solid fa-rotate-right"></font-awesome-icon>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content>
      <b-form-group>
        <b-input-group class="input-group-merge">
          <b-form-input
              id="search-apps"
              v-model="search.text"
              :debounce="250"
              autocomplete="off"
              size="md"
              placeholder="Search Instruments"
          />
          <b-input-group-append v-if="search.text" is-text>
            <feather-icon icon="XIcon" class="text-muted" @click="search.text = ''" />
          </b-input-group-append>
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" class="text-muted" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-row class="match-height">
        <b-col v-for="instrument in filteredInstruments" :key="instrument.id" cols="4">
          <b-card class="shadow-sm">
            <template>
              <b-link :to="{ name: 'all-state-selection', params: { id: instrument.id, slug: instrument.slug }}">
                <b-media no-body>
                  <b-media-aside class="media-left mr-1">
                    <b-avatar size="2.75rem" rounded variant="light-primary">
                      <font-awesome-icon :icon="icon" size="lg"></font-awesome-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="d-flex justify-content-between">
                    <div >
                      <h5 class="mb-0">{{ instrument.name }}</h5>
                      <template>
                        <small v-if="instrument.form" class="d-block mb-50">{{ instrument.form.name }}</small>
                        <small v-else class="d-block mb-50 text-danger">No Form</small>
                      </template>

                      <b-badge v-for="item in instrument.ensembles.items" :key="item.id"
                               variant="light-primary"
                               class="mr-05 font-small-1">
                        {{ item.ensemble.name }}
                      </b-badge>
                    </div>
                    <div>
                      <b-button size="sm" variant="light-primary" class="btn-icon" :to="{ name: 'all-state-selection-print', params: { id: instrument.id, slug: instrument.slug }}">
                        <feather-icon icon="PrinterIcon" size="14" />
                      </b-button>

                    </div>
                  </b-media-body>
                </b-media>
              </b-link>
            </template>
<!--            <template v-else>
              <b-link :to="{ name: 'all-state-selection', params: { id: instrument.id, slug: instrument.slug }}">
                <b-media no-body>
                  <b-media-aside class="media-left mr-1">
                    <b-avatar size="2.75rem" rounded variant="light-primary">
                      <font-awesome-icon :icon="icon" size="lg"></font-awesome-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">{{ instrument.name }}</h5>
                    <small>{{ instrument.form.name }}</small>
                  </b-media-body>
                </b-media>
              </b-link>
            </template>-->

          </b-card>
        </b-col>
      </b-row>

    </template>

    <template #debug>
      <debug>{{ instruments }}</debug>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import avatar from '@/mixins/avatar.mixin';
import notify from '@/mixins/notify.mixin';
import print from '@/mixins/print.mixin';
import vSelect from 'vue-select'
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listInstruments} from '@/graphql/queries/selections';
import Fuse from 'fuse.js';

export default {
  name: 'Selections',
  components: {
    PageLayout,
    vSelect
  },
  mixins: [ avatar, notify, print ],
  data() {
    return {
      user: null,
      instruments: {
        loading: true,
        items: []
      },
      search: {
        text: '',
        keys: [
          { name: 'name', weight: 2 },
          { name: 'form.name', weight: 1 },
          { name: 'ensembles.items.ensemble.name', weight: 1 },
        ]
      },
      icon: 'fas fa-check',
      debug: { }
    }
  },
  computed: {
    filteredInstruments() {
      if(this.search.text) {
        const fuse = new Fuse(this.instruments.items, {
          keys: this.search.keys,
          threshold: 0.1,
          ignoreLocation: true,
          shouldSort: true,
        })
        return fuse.search(this.search.text).map(({ item }) => item)
      }
      return this.instruments.items
    },

  },
  async created() {
    await this.getCurrentUser()
    await this.listInstruments();
  },
  methods: {
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listInstruments() {
      const response = await API.graphql(graphqlOperation(listInstruments, { ensembleIds: this.user.ensembleIds} ));

      if(this.user.groups.includes('NYSSMA_Chair')) {
          this.instruments.items = response.data.listInstruments.items
              .filter(instrument => instrument.ensembles.items.some(item => this.user.ensembleIds.includes(item.ensemble.id)))
              .sort((a, b) => a.name.localeCompare(b.name))

          this.instruments.items.forEach(instrument => {
              instrument.ensembles.items = instrument.ensembles.items
                  .filter(item => this.user.ensembleIds.includes(item.ensemble.id))
                  .sort((a, b) => a.ensemble.name.localeCompare(b.ensemble.name))
          })
      }
      else if(this.user.groups.includes('NYSSMA_Committee')) {
        this.instruments.items = response.data.listInstruments.items
            .filter(instrument => this.user.instrumentIds.includes(instrument.id))
            .sort((a, b) => a.name.localeCompare(b.name))

        this.instruments.items.forEach(instrument => {
            instrument.ensembles.items.sort((a, b) => a.ensemble.name.localeCompare(b.ensemble.name))
        })
      }
      else {
        this.instruments.items = response.data.listInstruments.items.sort((a, b) => a.name.localeCompare(b.name));
      }

      this.instruments.loading = false
      this.$refs.layout.state.loading = false
    },
    async refresh() {
      this.instruments.loading = true
      this.$refs.layout.state.loading = true
      await this.listInstruments()
    },
  }
}
</script>

<style lang="scss">

</style>
